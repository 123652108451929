$(document).ready(function () {
  var data_null = false;

  $(".next-step").click(function (e) {
    data_null  = true
    var active = $('.wizard .nav-tabs li.active');

    if (active.data('step') == 'step4') {
      var validation_form_step = validationFormStep4()
      if (validation_form_step.includes(true)) {
        data_null = false
        alert(I18n.t('global.alerts.country_code_or_phone_number_cant_be_blank'))
        return false
      }
    }

    active.next().removeClass('disabled');
    nextTab(active);
    data_null = false

    autoFormSubmit($(this))
  });

  $(".prev-step").click(function (e) {
    data_null = true
    var active = $('.wizard .nav-tabs li.active');

    if (active.data('step') == 'step4') {
      var validation_form_step = validationFormStep4()
      if (validation_form_step.includes(true)) {
        data_null = false
        alert(I18n.t('global.alerts.country_code_or_phone_number_cant_be_blank'))
        return false
      }
    }

    prevTab(active);
    data_null = false

    autoFormSubmit($(this))
  });

  $('.nav-tabs > li a[title]').tooltip();
  $('a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
    var target = $(e.target);
    if (target.parent().hasClass('disabled')) {
      return false;
    }
  });

  $('.nav-tabs').on('click', 'li', function() {
    var tab_active       = $('.nav-tabs li.active')
    var data_tab_active  = tab_active.children().data('tab')
    var next_data        = $(this).children().data('tab')
    var tab_active_split = parseInt(data_tab_active.split('step')[1])
    var next_data_split  = parseInt(next_data.split('step')[1])

    var next_wizard = tab_active_split + 1
    var prev_wizard = tab_active_split - 1
    var this_wizard = parseInt($(this).children().data('tab').split('step')[1])

    if (tab_active.data('step') == 'step4') {
      var validation_form_step = validationFormStep4()
      if (validation_form_step.includes(true)) {
        data_null = false
        alert(I18n.t('global.alerts.country_code_or_phone_number_cant_be_blank'))
        return false
      }
    }

    if (next_wizard == this_wizard) {
      tab_active.removeClass('active');
      $(this).addClass('active');
      if (data_null != true) {
        autoFormSubmit(tab_active)
      }
    } else if (prev_wizard == this_wizard) {
      tab_active.removeClass('active');
      $(this).addClass('active');
      if (data_null != true) {
        autoFormSubmit(tab_active)
      }
    } else {
      return false;
    }
  });

  // add class select2 size form wizard
  $('.custom-select2-form-wizard').find('.select2-selection--multiple').addClass('select2-container-size');
  $('.custom-select2-form-wizard').find('.select2-container--default').addClass('select2-size');

  // disabled submit when klik enter
  $('#form-wizard').on('keyup keypress', function(e) {
    var keyCode = e.keyCode || e.which;
    if (keyCode === 13) {
      e.preventDefault();
      return false;
    }
  });
});

function nextTab(elem) {
  $(elem).next().find('a[data-toggle="tab"]').click();
}

function prevTab(elem) {
  $(elem).prev().find('a[data-toggle="tab"]').click();
}

function autoFormSubmit($this) {
  var step       = $this.data('step');
  var account_id = $('#connecting_star_account_id').val()

  var formData   = { 'connecting_star': { 'account_id': account_id, 'set_your_goal': {}, 'action_plan': {}, 'build_relief_faith': {}, 'actions': {} } }
  var data_array = []

  if (step == 'step1') {
    formData['connecting_star']['dreams'] = $(`#${step}`).find('input[name^="connecting_star[dreams]"]').map(function(){return $(this).val();}).get()
  }

  if (step == 'step2') {
    $.each($('#tier_ids').data('tier-ids'), function(idx, tier_id) {
      seat_your_goal = $(`#seat_your_goal_${tier_id}`).val()
      date           = $(`#date_${tier_id}`).val()
      reward         = $(`#reward_${tier_id}`).val()

      formData['connecting_star']['set_your_goal'][`${tier_id}`] = { 'seat_goal': seat_your_goal, 'date': date, 'reward': reward }
    })
  }

  if (step == 'step3') {
    $.each($('.action_plan_step3'), function() {
      var name_form = $(this).attr('data_name')
      var value     = $(this).is(':checked') ? '1' : '0'

      formData['connecting_star']['action_plan'][name_form] = value
    })

    formData['connecting_star']['action_plan']['event_ids'] = $('#connecting_star_action_plan_event_ids').val()
  }

  if (step == 'step4') {
    var last_numbers = parseInt($('#add-name-lists').attr('data-last-number'))
    var ranges       = range(last_numbers)

    $.each(ranges, function(key, num) {
      var name_page = `name_${num}`
      var name_hash = {}

      $.each($(`[name^='connecting_star[actions][${name_page}]']`), function() {
        name_hash[$(this).attr('data_name')] = $(this).val()
      })

      formData['connecting_star']['actions'][name_page] = name_hash
      name_hash = {}
    })
    formData['connecting_star']['actions']['date'] = $('#connecting_star_actions_date').val()
  }

  if (step == 'step5') {
    $.each($('#step5').find('input[type="checkbox"]'), function() {
      var value = $(this).is(':checked') ? '1' : '0'

      formData['connecting_star']['build_relief_faith'][$(this).attr('data_name')] = value
    })

    formData['connecting_star']['build_relief_faith']['Date'] = $('#connecting_star_build_relief_faith_Date').val()
  }

  $.ajax({
    type: $("input[name='_method']").length == 0 ? 'POST' : 'PUT',
    dataType: "script",
    data: formData,
    url: $('form').attr('action')
  });
}

function range(size, startAt = 1) {
  return [...Array(size).keys()].map(i => i + startAt);
}

function validationFormStep4() {
  var arr_checked_phone_number = []

  $.each($('.country_code_actions'), function() {
    var country_code = $(this).find('select').val()
    var telp_code    = $(this).find('input').val()

    if (!!country_code) {
      if (!telp_code) {
        arr_checked_phone_number.push(true)
      }
    }

    if (!!telp_code) {
      if (!country_code) {
        arr_checked_phone_number.push(true)
      }
    }
  })

  return arr_checked_phone_number
}
