$(document).ready(function(){
  $('.uppy-FileInput-btn').attr('style', 'display: none;');

  validationSubmit();
  deleteField();
  selectOptionFilter();
  applyFunction();

  var removed_tier_ids = [];

  $('.add_fields').click(function() {
    var timeout = null;

    clearTimeout(timeout);

    var quantity = $(this).val();
    var idx      = $(this).attr("data-id");
    timeout = setTimeout(() => {
    applyFunction();
    deleteField();
    selectOptionFilter();
    }, 100);
  })

  $('#btn-choose-photo').click(function() {
    $('#achievement-img-form-gallery').modal({backdrop: 'static', keyboard: false});
  });

  $('#select-image-gallery').click(function(){
    var gallery_id = $('input[name="achivement_submission[account_picture_gallery_id]"]:checked').val();

    if (!(gallery_id == undefined)) {
      $('#image-upload').val("");
      $('#hidden-picture-id').prop('disabled', false);
      $('#hidden-picture-id').val(gallery_id);

      var gallery_src = $(`#select-gallery-img-${gallery_id}`).attr('src');
      $('.achievement-submission-previewer').attr('src', gallery_src);
    }
  });

  $('#btn-open-upload-file').click(function(){
    $('.uppy-FileInput-btn').click();
  });

  function applyFunction() {
    $('.month-year-datepicker').bootstrapDP({
      format: "mm/yyyy",
      startView: "months",
      minViewMode: "months",
      endDate: "today"
    });

    // $('.select2').select2()
  }

  function deleteField() {
    $('.remove_fields').click(function() {
      var nestedWrapper = $(this).parents('.nested-fields');
          console.log(nestedWrapper)
          nestedWrapper.find('input._destroy_field').val(true);
          nestedWrapper.find('input').removeAttr('required');
          nestedWrapper.find('select').removeAttr('required');
          nestedWrapper.find('select').val('');
          nestedWrapper.addClass('d-none');
          nestedWrapper.removeClass('nested-fields');

      if ($(this).attr('data-edit-record') == "true") {
        var tier_id = $(this).attr('data-tier-id');
        if (!removed_tier_ids.includes(tier_id)) {
          removed_tier_ids.push(tier_id);
        }
      }
    })
  }

  function validationSubmit() {
    $('.btn-submit').click(function() {
      var nested_fields = $('.nested-fields').length;
      var image_upload  = $('#image-upload').val().length;
      var error_msg     = [];

      if ($('input[name="achivement_submission[account_picture_gallery_id]"]:checked').val() == undefined) {
        var gallery_img = 0;
      } else {
        var gallery_img = $('input[name="achivement_submission[account_picture_gallery_id]"]:checked').val().length;
      }

      if (nested_fields <= 0) {
        error_msg.push(I18n.t('global.alerts.pin_level_cant_be_blank'));
      }

      if ((image_upload <= 0) && (gallery_img <= 0)) {
        error_msg.push(I18n.t('global.alerts.image_cant_be_blank'));
      }

      if (error_msg.length > 0) {
        alert(error_msg.toString());
        return false
      }
    })
  }

  function selectOptionFilter() {
    $(`.pin_level_select`).focus(function(){
      var tier_field = $(this);
      var value      = tier_field.val();
      var except_tier_ids = [];

      $('.pin_level_select').each(function() {
        condition = $(this).val() == value;

        if (!condition) {
          except_tier_ids.push($(this).val());
        }
      });

      updateTierOptions(tier_field, except_tier_ids, value);
    })
  }

  function updateTierOptions(tier_field, except_tier_ids, selected_tier_id) {
    var new_selected_tier_ids = [];

    $('.pin_level_select').each(function() {
      var nestedWrapper = $(this).parents('.nested-fields');
      var delete_input  = nestedWrapper.find('input._destroy_field').val();

      if (!($(this).val() == '') && (delete_input == "false")) {
        new_selected_tier_ids.push($(this).val());
      }
    });

    $.ajax({
      type: "GET",
      dataType:"json",
      data: { 'except_tier_ids' : except_tier_ids, 'new_selected_tier_ids' : new_selected_tier_ids, 'removed_tier_ids' : removed_tier_ids },
      url: "/api/services/get_tier_level",
      success: function(response){
        data  = response['tiers'];

        if (!data) {
          var error_message = `<div class="alert alert-danger" role="alert">`+
                                I18n.t('global.alerts.session_expired')+
                              `</div>`;
          $('#error_messages').html(error_message);
          location.reload();
        } else {
          $(tier_field).find('option').remove();
          $.each(data, function(_key, data) {
            let option = $("<option></option>");
                option.attr("value", data[1]);
                option.text(`${data[0]}`);
                if (selected_tier_id == data[1]) {
                  option.attr('selected', true);
                }
            $(tier_field).append(option);
          })
        }
      }
    });
  }

  function remove(arrOriginal, elementToRemove){
    return arrOriginal.filter(function(el){return el !== elementToRemove});
  }
});