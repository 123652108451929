$(document).ready(function(){
  // Membership Renewal or BOM Subscription
  var membership_renewal_page = $('.address-details').data('page') === 'membership_renewal'
  if (membership_renewal_page) {
    var wrapper_package_details = $('.wrapper-select-subscription').find('input[name="membership_package_id"]:checked').data('package-details');
    conditionPackageDetail(wrapper_package_details);
  }

  $('.wrapper-select-subscription').click(function(){
    var id    = $(this).attr('data-id');
    var price = parseInt($(`#package-price-${id}`).val());
    var current_mutation = parseInt($('#current-mutation').val());
    
    if (current_mutation >= price) {
      $('.allow-balance').removeClass('hide');
    } else {
      $('.allow-balance').addClass('hide');
    }

    if (current_mutation > 0 && current_mutation < price) {
      $('.allow-molpay-balance').removeClass('hide');
      $('#use_balance').attr('checked', true);
      $('#use_balance').removeAttr('disabled');
    } else {
      $('.allow-molpay-balance').addClass('hide');
      $('#use_balance').attr('checked', false);
      $('#use_balance').attr('disabled', true)
    }

    if ($('.address-details').data('page') === 'membership_renewal') {
      var package_details = $(this).find('input[name="membership_package_id"]:checked').data('package-details');
      conditionPackageDetail(package_details);
    }
  });

  $("#terms-member-subscription").change(function(){
    allowSubmitSubsription();
  });

  $('.payment-check-subscription').click(function(){
    allowSubmitSubsription();
  });

  function conditionPackageDetail(data_package_details) {
    if (!data_package_details) {
      hideAddress();
    } else {
      showAddress();
    }
  }

  function hideAddress() {
    $('.address-details').addClass('hide');
    addAttr($('input[data-package-details="true"]'));
    addAttr($('input[name="address_id"]'));
  }

  function showAddress() {
    $('.address-details').removeClass('hide');
    removeAttr($('input[data-package-details="true"]'));
    removeAttr($('input[name="address_id"]'));
  }

  function removeAttr(classes) {
    $.each(classes, function() {
      $(this).removeAttr('disabled')
    })
  }

  function addAttr(classes) {
    $.each(classes, function() {
      $(this).attr('disabled', true)
    })
  }

  function allowSubmitSubsription(){
    var condition1 = $('input[name=payment_check]:checked').val() != undefined;
    var condition2 = $("#terms-member-subscription").is(':checked');

    if (condition1 && condition2) {
      $("#submit-subscription").prop('disabled', false);
    } else {
      $("#submit-subscription").prop('disabled', true);
    }
  }

  $("#submit-subscription").click(function(){
    var value   = $('.select-subscription:checked').val();
    var type    = $(this).attr('data-subs-type');
    var address = $('input[name="address_id"]').is(':checked');
    var data_package_details = $('input[name="membership_package_id"]:checked').data('package-details');
    var data_page            = $('.address-details').data('page');
    var skip_check_address   = $(this).attr('data-skip-check-address');

    if (!(skip_check_address == 'true')) {
      if (data_page == 'membership_renewal') {
        if (!address && data_package_details) {
          alert(I18n.t('global.alerts.address_cant_be_blank'));
          return false
        }
      } else {
        if (!address) {
          alert(I18n.t('global.alerts.address_cant_be_blank'));
          return false
        }
      }
    }

    if (value == undefined) {
      var r = confirm(  I18n.t('global.please_select') +`${Package}!`);
    } else {
      var r = confirm(I18n.t('global.notices.are_you_sure'));
      if (r == false) {
        return false
      }
    }
  });

  $("#form-subscription").submit(function () {
    $("#submit-subscription").attr("disabled", true);
    return true;
  });

  // End Code
});