$(document).ready(function() {
  // Nationality Validation
  $('#select-nationality').change(function(){
    var value        = $(this).val();
    var wrapper_nric = $('#wrapper-nric');
    var input_nric   = $('#nric');
    var wrapper_national_id = $('#wrapper-national-id');
    var input_national_id   = $('#national-id-number');
    showHideNric(value, wrapper_nric, input_nric, wrapper_national_id, input_national_id);

    var other_id_type = $('#other-id-type');
    var other_id_no   = $('#other-id-no');
    otherIdValidation(value, other_id_type, other_id_no);
  }).change();

  $('#select-partner-nationality').change(function(){
    var value        = $(this).val();
    var wrapper_nric = $('#wrapper-partner-nric');
    var input_nric   = $('#partner-nric');
    var wrapper_national_id = $('#wrapper-partner-national-id');
    var input_national_id   = $('#partner-national-id-number');
    showHideNric(value, wrapper_nric, input_nric, wrapper_national_id, input_national_id);

    var other_id_type = $('#partner-other-id-type');
    var other_id_no   = $('#partner-other-id-no');
    otherIdValidation(value, other_id_type, other_id_no);
  }).change();

  function showHideNric(value, wrapper_nric, input_nric, wrapper_national_id, input_national_id){
    $.ajax({
      type: "GET",
      dataType:"json",
      data: { 'country_id' : value },
      url: "/api/services/get_country_id_number_type",
      success: function(response){
        var id_number_type = response.id_number_type;

        setTimeout(() => {
          if(id_number_type == 'nric') {
            wrapper_nric.removeClass('d-none');
            input_nric.prop('required', true);

            wrapper_national_id.addClass('d-none');
            input_national_id.val(null);
            input_national_id.prop('required',false);
          } else if(id_number_type == 'national_id_number') {
            wrapper_national_id.removeClass('d-none');
            input_national_id.prop('required', true);

            wrapper_nric.addClass('d-none');
            input_nric.prop('required',false);
            input_nric.val(null);
          } else {
            wrapper_national_id.addClass('d-none');
            input_national_id.val(null);
            input_national_id.prop('required',false);

            wrapper_nric.addClass('d-none');
            input_nric.prop('required',false);
            input_nric.val(null);
          }
        }, 100)

      }
    });
  }

  function otherIdValidation(value, other_id_type, other_id_no){
    if (value == '1') {
      other_id_type.prop('required', false);
      other_id_no.prop('required', false);
    } else {
      other_id_type.prop('required', true);
      other_id_no.prop('required', true);
    }
  }
  // END Nationality Validation

  // Edit foto in page index profile
  $('#edit_avatar_uploader').change(function() {
    $('.edit_user').submit();
  });

  // Update set default address in mailing list profile page user
  $(".mailing__card .card-body form").click(function () {
    $(this).find("input").prop("checked", true).change()
  })

  // Get upline platinum name on page sponsor information profiles page
  var timeout = null;

  $("#input-platinum-abo").keyup(function(){
    clearTimeout(timeout);

    var abo_number = $(this).val();
    var account_id = $(this).data('account');
    var only_platinum_above = $(this).data('only-platinum-above');

    timeout = setTimeout(() => {
      platinumUpline(abo_number, account_id, only_platinum_above)
    }, 1000);
  });

  // Get active system upline name, ID, Recognition Name
  $("#active-system-upline").keyup(function(){
    clearTimeout(timeout);

    var abo_number = $(this).val();
    var account_id = $(this).data('account');
    var only_platinum_above  = $(this).data('only-platinum-above');
    var auto_fill_platinum   = $(this).data('auto-fill-platinum');
    var only_my_group        = $(this).data('only-my-group');
    var with_current_account = $(this).data('with-current-account');

    timeout = setTimeout(() => {
      var data_params = {
        "abo_number" : abo_number,
        "account_id" : account_id,
        "only_platinum_above" : only_platinum_above,
        "auto_fill_platinum" : auto_fill_platinum,
        "only_my_group" : only_my_group,
        "with_current_account" : with_current_account,
      }
      activeSystemUpline(data_params);
    }, 1000);
  });

  function activeSystemUpline(data_params) {
    $.ajax({
      type: "GET",
      dataType:"json",
      data: data_params,
      url: "/api/services/get_platinum_active_system_upline",
      success: function(response){
        var upline   = response.active_system_upline || {};
        var platinum = response.platinum_upline || {};

        $("#active-system-upline-name").val(upline["tmp_name"]);
        $("#active-system-upline-id").val(upline["id"]);

        if (data_params["auto_fill_platinum"] == true) {
          $("#tmp-platinum-upline-name").val(platinum["tmp_name"]);
          $("#platinum-upline").val(platinum["name"]);
          $(".platinum-abo-number").val(platinum["abo"]);
          $("#platinum-upline-id").val(platinum["id"]);
        }

        if (upline["name"] == undefined) {
          if ($('#error-message-active-system-upline-abo').length < 1) {
            $('.error-msg-active-system-upline-abo-number').after("<p class='text-danger' id='error-message-active-system-upline-abo'>" + I18n.t('global.alerts.active_system_upline_number_not_found') + "</p>");
          }
        } else {
          $('#error-message-active-system-upline-abo').remove();
          $('#error-message-abo').remove();
        }
      }
    });
  }

  function platinumUpline(abo_number, account_id, only_platinum_above) {
    $.ajax({
      type: "GET",
      dataType:"json",
      data: { 'abo_number' : abo_number, 'account_id' : account_id, 'only_platinum_above' : only_platinum_above },
      url: "/api/services/get_platinum_data",
      success: function(response){
        var upline = response.platinum || {};

        $("#tmp-platinum-upline-name").val(upline["tmp_name"]);
        $("#platinum-upline").val(upline["name"]);
        $("#platinum-abo-number").val(upline["abo"]);
        $("#platinum-upline-id").val(upline["id"]);

        if (upline["name"] == undefined) {
          if ($('#error-message-abo').length < 1) {
            $('#input-platinum-abo').after("<p class='text-danger' id='error-message-abo'>" + I18n.t('global.alerts.platinum_upline_abo_number_not_found') + "</p>");
          }
        } else {
          $('#error-message-abo').remove();
        }
      }
    });
  }
});
