$(document).ready(function() {
  // Pricing tier input quantity on page carts index
  var timeout = null;

  // Pricing tier input quantity on page events show
  updateTotalCart(timeout);
  UpdateItemQty(timeout);

  const callUpdateTotalCart = () => updateTotalCart(timeout);
  window.callUpdateTotalCart = callUpdateTotalCart;

  const callUpdateItemQty = () => UpdateItemQty(timeout);
  window.callUpdateItemQty = callUpdateItemQty;
});

function updateTotalCart(timeout) {
  $(".input-quantity").keyup(function(){
    clearTimeout(timeout);

    var quantity     = $(this).val();
    var idx          = $(this).attr("data-id");
    var cart_item_id = $(`input[name="cart_item[id][${idx}]"]`).val();

    var event_id       = $(this).attr("data-event-id");

    var $event      = $(`#event-limit-${event_id}`).first();
    var event_limit = $event.data('event-limit') || 0;
    var event_name  = $event.data('event-name');
    var category_type      = $(`#category-type-${idx}`).val();
    var purchased_ticket   = parseInt($event.data('my-ticket'));

    var qty_tickets_input  = $('.input-quantity.ticket-category');

    if (qty_tickets_input.length > 0) {
      var total_quantity = $(`.input-quantity.ticket-category[data-event-id="${event_id}"]`).map((_,el) => parseInt(el.value)).get().reduce(function(a, b) {
                            return parseInt(a, 10) + parseInt(b, 10);
                           });

      if (event_limit > 0 && category_type == 'ticket') {
        if ((purchased_ticket + total_quantity) > event_limit) {
          errorEventLimit($(this), event_limit, event_name, quantity);
          return false
        }
      }
    }

    timeout = setTimeout(() => {
      calculatePricingTier(quantity, idx, cart_item_id)
    }, 1000);
  });

  $(".input-quantity").change(function(){
    clearTimeout(timeout);

    var quantity     = $(this).val();
    var idx          = $(this).attr("data-id");
    var cart_item_id = $(`input[name="cart_item[id][${idx}]"]`).val();

    var event_id       = $(this).attr("data-event-id");

    var $event      = $(`#event-limit-${event_id}`).first();
    var event_limit = $event.data('event-limit') || 0;
    var event_name  = $event.data('event-name');
    var category_type    = $(`#category-type-${idx}`).val();
    var purchased_ticket = parseInt($event.data('my-ticket') || 0);

    var qty_tickets_input  = $('.input-quantity.ticket-category');

    if (qty_tickets_input.length > 0) {
      var total_quantity = $(`.input-quantity.ticket-category[data-event-id="${event_id}"]`).map((_,el) => parseInt(el.value)).get().reduce(function(a, b) {
                            return parseInt(a, 10) + parseInt(b, 10);
                           })

      if (event_limit > 0 && category_type == "ticket") {
        if ((purchased_ticket + total_quantity) > event_limit) {
          errorEventLimit($(this), event_limit, event_name, quantity);
          return false
        }
      }
    }

    timeout = setTimeout(() => {
      calculatePricingTier(quantity, idx, cart_item_id)
    }, 1000);
  });

  function calculatePricingTier(quantity, idx, cart_item_id){
    var checkedVals = $('.select-items:checkbox:checked').map(function() {
        return this.value;
    }).get();

    console.log(checkedVals)
    $("#select-cart-item-ids").val(checkedVals);

    $.ajax({
      type: "GET",
      dataType: "script",
      data: {'quantity' : quantity, 'cart_item_id' : cart_item_id, 'idx': idx, 'selected_cart_item_ids': checkedVals},
      url: "/user/carts/update_quantity"
    });
  }
}

function UpdateItemQty(timeout) {
  $(".item-quantity").change(function(){
    itemQuantityChange($(this), timeout);
  });

  $(".item-quantity").keyup(function(){
    itemQuantityChange($(this), timeout);
  });
}

function errorEventLimit($this, event_limit, event_name, quantity) {
  // console.log(quantity)
  $this.val(0);

  var msg = I18n.t('global.alerts.you_have_reached_maximum_ticket_purchase')
  alert(`${msg} `+ I18n.t('global.alerts.you_are_only_allowed_to_buy') +` ${event_limit} `+ I18n.t('global.alerts.ticket_for_event') +` '${event_name}'`);
}

function itemQuantityChange(_this, timeout) {
  clearTimeout(timeout);

  var quantity = $(_this).val();
  var idx      = $(_this).attr("data-id");

  var $event         = $('#event-limit');
  var event_limit    = parseInt($event.data('event-limit')) || 0;
  var event_name     = $event.data('event-name');
  var category_type  = $(`#category-type-${idx}`).val();
  var purchased_ticket = parseInt($event.data('my-ticket'));

  var qty_tickets_input  = $('.item-quantity.ticket-category');

  if (qty_tickets_input.length > 0) {
    var total_quantity = $('.item-quantity.ticket-category').map((_,el) => parseInt(el.value)).get().reduce(function(a, b) {
                          return parseInt(a, 10) + parseInt(b, 10);
                         })
    // var msg = '';

    if (event_limit > 0 && category_type == 'ticket') {
      if ((purchased_ticket + total_quantity) > event_limit) {
        errorEventLimit($(_this), event_limit, event_name, quantity);
        return false
      }
    }
  }

  timeout = setTimeout(() => {
    calculateTotalItem(quantity, idx);
  }, 1000);
}

function calculateTotalItem(quantity, idx){
  $.ajax({
    type: "GET",
    dataType:"json",
    data: {'quantity' : quantity, 'idx' : idx},
    url: "/user/events/get_quantity_pricing",
    success: function(response){
      if (response['quantity'] == 0 || response['pricing'] == null) {
        $(`#total-${idx}`).addClass('d-none')
        $(`#price-${idx}`).removeAttr('style')
      } else {
        $(`#total-${idx}`).removeClass('d-none')
        $(`#price-${idx}`).attr('style', 'text-decoration: line-through')
        $(`#total-${idx}`).text(`RM ${response['price'].toString()}.00`)
      }
    }
  });
}