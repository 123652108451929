$(document).ready(function(){
  var accordionContent = $(this).find('.accordionContent').hide();
  var accordionUplineContent = $(this).find('.accordionUplineContent').hide();

  $('body').on('click', '.downliner-tree-account', function(){
    var $this      = $(this);
    var level      = parseInt($this.attr('data-parent'));
    var account_id = $this.attr('data-account-id');
    var hasChild   = $this.attr('data-haschild');
    var wrapper_child = $(`#wrapper-child-${account_id}`);
    // var $target    = $this.find('.accordionContent[data-child="'+level+'"]');
    var parent_li     = $this.closest(`.wrap-parent li.li-${account_id}`);
    var $target       = parent_li.find('.accordionContent[data-child="'+level+'"]');
    var params_events = $this.attr('data-params-event')

    if (wrapper_child.length > 0) {
      wrapper_child.removeClass('hide');
      // wrapper_child.toggleClass('blink');
    }

    if (!hasChild) {
      $.ajax({
        type: "GET",
        dataType:"json",
        data: { "data_parent" : level, "account_id" : account_id, "event_ids" : params_events },
        url: `/user/major_functions/children_major_function`,
        success: function(response){
          var data = "";
          var platinum_position = response.platinum_position;
          $.each( response.children, function( k, child ) {
            // console.log(k, child)
            var child_id          = child.id;
            var recognition_name  = child.recognition_name;
            var abo_number        = child.abo_number;
            var pin               = child.pin;
            var pin_position      = child.pin_position;
            var total_active_user = child.total_active_user;
            var own_ticket        = child.own_ticket;
            var total_ticket      = child.total_tickets;
            var balance_ticket    = child.balance_tickets;
            var parent_id         = child.parent_id;

            if (parseInt(total_active_user) >= 2) {
              var show_caret = "zmdi zmdi-caret-right";
              var wrapper_child_c = `<ul class="list-unstyled wrap-parent ml-3 hide" id="wrapper-child-${child_id}">` +
                                      `<li>` +
                                        `<div class="d-flex organization-list"></div>` +
                                      `</li>` +
                                    `</ul>`;
            } else {
              var show_caret = "";
              var wrapper_child_c = "";
            }

            var color = '';
            var is_balance = 'true';

            if (level+1 == 2) {
              if (pin_position >= platinum_position) {
                var is_balance = 'false';
              }
            }

            if (level+1 > 2) {
              if (parent_id != '') {
                var is_balance = $(`#balance-${parent_id}`).attr("data-balance");
              }
            }

            if (is_balance == 'true') {
              var color = 'blue';
              // var balance_ticket_text = '';
            } //else {
              // var balance_ticket_text = `Balance Tickets : ${balance_ticket}`;
            // }

            var balance_ticket_text = I18n.t('ticket_voucher.balance_tickets') + ` : ${balance_ticket}`;

            var children_table = `<ul class="list-unstyled ml-3 accordionContent" data-child="${level}">` +
                                    `<li class="li-${child_id}">` +
                                      `<div class="text-decoration-none organization-list-wrap align-items-center">` +
                                        `<div id='balance-${child_id}' data-balance="${is_balance}"></div>` +
                                          `<div class="d-flex justify-content-between organization-list">` +
                                            `<div class="d-flex w-100">` +
                                              `<div class="organization-icon remove_icon downliner-tree-account" data-parent="${level+1}" data-account-id="${child_id}" id="account-${child_id}" data-params-event="${params_events}">` +
                                                `<i class="${show_caret}"></i>` +
                                              `</div>` +
                                              `<div class="ml-3 w-100">` +
                                                `<span class="d-block">` +
                                                  `${recognition_name}<b>(${abo_number})</b>` +
                                                `</span>` +
                                                `<div class="row">` +
                                                  `<div class="col-md-3">` +
                                                    `<span style="color: ${color}">` + I18n.t('global.pin') + ` : ${pin}</span>` +
                                                  `</div>` +
                                                  `<div class="col-md-3">` +
                                                    `<span style="color: ${color}">` + I18n.t('ticket_voucher.own_tickets') + ` : ${own_ticket}</span>` +
                                                  `</div>` +
                                                  `<div class="col-md-3">` +
                                                    `<span style="color: ${color}">${balance_ticket_text}</span>` +
                                                  `</div>` +
                                                  `<div class="col-md-3">` +
                                                    `<span style="color: ${color}">` + I18n.t('ticket_voucher.total_tickets') + ` : ${total_ticket}</span>` +
                                                  `</div>` +
                                                `</div>` +
                                              `</div>` +
                                            `</div>` +
                                          `</div>` +
                                        `</div>` +
                                      `</div>` +
                                      wrapper_child_c +
                                    `</li>` +
                                 `</ul>`;

            data = data + children_table;
          });
          // wrapper_child.removeClass('blink');
          wrapper_child.html(data);
          $this.addClass('active-icon');
          $this.attr('data-haschild', true);

          if (data.length > 0) {
            $this.find('.zmdi').first().removeClass('zmdi-caret-right').addClass('zmdi-caret-down');
          } else {
            $this.find('.zmdi').first().removeClass('zmdi-caret-right');
          }
        }
      })
    }

    if($target.is(':hidden')){
      $this.removeClass('active-icon');
      $this.addClass('active-icon');
      $target.removeClass('active').slideUp();
      $target.addClass('active').slideDown();
      $this.find('.zmdi').first().removeClass('zmdi-caret-right').addClass('zmdi-caret-down');
    } else {
      var elm_obj = $this.find('.zmdi').first();
      if (elm_obj.hasClass('zmdi-caret-down')) {
        elm_obj.removeClass('zmdi-caret-down').addClass('zmdi-caret-right');
      }
      $this.removeClass('active-icon');
      $target.removeClass('active').slideUp();
    }

    return false;
  });
});
