$(document).ready(function() {
  document.querySelectorAll('.remove-image').forEach( function(elm, id) {
    elm.addEventListener('click', function(){
      var data_confirm = confirm(I18n.t('global.notices.are_you_sure'));
      if (data_confirm) {
        var data_pic = document.querySelector(elm.getAttribute('data-pic-id'));

        if ($(elm).attr('data-is-video') == 'true') {
          var preview = elm.closest('li')
          preview.className += ' hide'
        } else {
          var preview = data_pic;
          preview.className += ' hide'
        }

        var id = $(elm).attr('data-id');
        var destroyField = data_pic.querySelector(`.destroy-field-${id}`);
        destroyField.value = true
        destroyField.setAttribute('value', true)
        return true;
      } else {
        return false;
      }

    })
  })

  $('body').on('click', '.remove-cache-image', function(){
    if (!$(this).hasClass('remove-cache-weigh-out')) {
      var data_confirm = confirm(I18n.t('global.notices.are_you_sure'));
      if (data_confirm) {
        var elm = this;
        var imageCol = elm.closest('.image-col')
        if (imageCol == null) { imageCol = elm.closest('li') }

        if (elm.dataset.isVideo) {
          var imageField = document.querySelector('.upload-data-video')
        } else {
          var imageField = document.querySelector('.upload-data')
        }

        var jsonData   = JSON.parse(imageField.value)
        var newJson    = jsonData.filter(function(elm){

          var data_cache_id = imageCol.getAttribute('data-cache-id');

          // return (elm.id != data_cache_id) && ((elm.id.length > 0) || (data_cache_id != null))
          if (elm.id.length > 0 || data_cache_id != null) {
            return elm.id != data_cache_id;
          } else {
            return false
          }
        })

        imageField.value = JSON.stringify(newJson)
        imageCol.remove()
        return true;
      } else {
        return false;
      }
    }
  })

  $('body').on('click', '.remove-cache-image.remove-cache-weigh-out', function(){
    var data_confirm = confirm(I18n.t('global.notices.are_you_sure'));
    if (data_confirm) {
      var elm = this;
      var imageCol = elm.closest('.image-col')
      if (imageCol == null) { imageCol = elm.closest('li') }

      if (elm.dataset.isVideo) {
        var imageField = document.querySelector('#upload-data-weigh-out-video');
      } else {
        var imageField = document.querySelector('#upload-weigh-out-data');
      }

      var jsonData   = JSON.parse(imageField.value)
      var newJson    = jsonData.filter(function(elm){
        var data_cache_id = imageCol.getAttribute('data-cache-id');

        // return (elm.id != data_cache_id) && ((elm.id.length > 0) || (data_cache_id != null))
        if (elm.id.length > 0 || data_cache_id != null) {
          return elm.id != data_cache_id;
        } else {
          return false
        }

      })

      imageField.value = JSON.stringify(newJson)
      imageCol.remove()
      return true;
    } else {
      return false;
    }

  })

  $('img.show-image').on('click', function(){
    var currentUrl = this.getAttribute('src')
    var changedPic = document.querySelector('.current-image')
    var changedUrl = changedPic.getAttribute('src')
    changedPic.setAttribute('src', currentUrl)
    this.setAttribute('src', changedUrl)
  })
})
