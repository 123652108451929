$(document).ready(function(){
  window.editOrganization = function(e) {
   e.stopPropagation();
  }

  var accordionContent = $(this).find('.accordionContent').hide();
  var accordionUplineContent = $(this).find('.accordionUplineContent').hide();

  $('body').on('click', '.accordion-item', function(){
    var $this      = $(this);
    var level      = parseInt($this.attr('data-parent'));
    var account_id = $this.attr('data-account-id');
    var hasChild   = $this.attr('data-haschild');
    var wrapper_child = $(`#wrapper-child-${account_id}`);
    var parent_li  = $this.closest(`.wrap-parent li.li-${account_id}`);
    var $target    = parent_li.find('.accordionContent[data-child="'+level+'"]');
    // var $target    = $this.find('.accordionContent[data-child="'+level+'"]');
    var event_id   = $this.attr('data-event-id');
    var variant_id = $this.attr('data-variant-id');
    var user_detail_url      = $("#pills-tabContent").attr("data-detail-user-url") || "";
    var amway_pin_detail_url = $("#pills-tabContent").attr('data-detail-pin-url') || "";
    var tqg_level_detail_url = $("#pills-tabContent").attr('data-detail-tqg-level-url') || "";

    if (event_id == undefined) {
      event_id = null;
    }

    if (variant_id == undefined) {
      event_id = null;
    }

    var with_guest = $('#organization-details').attr("data-with-registered-guest");

    if (with_guest == undefined) {
      var with_guest = false
    }
    
    data_json = { "data_parent" : level, 'event_id' : event_id, 'variant_id' : variant_id, 'with_registered_as_guest' : with_guest }

    if (wrapper_child.length > 0) {
      wrapper_child.removeClass('hide');
      // wrapper_child.toggleClass('blink');
    }

    if (!hasChild) {
      var main_path = window.location.pathname.includes("/user/") ? "user" : "admin";

      $.ajax({
        type: "GET",
        dataType:"json",
        data: data_json,
        url: `/${main_path}/organization_charts/${account_id}/get_children`,
        success: function(response){
          var data = "";
          $.each( response.children, function( k, child ) {
            var child_id          = child.id;
            var user_id           = child.user_id;
            var recognition_name  = child.recognition_name;
            var abo_number        = child.abo_number;
            var expired_style     = child.expired_style;
            var expiry            = child.expiry;
            var deactivated       = child.deactivated;
            var deactivated_style = child.deactivated_style;
            var tqg_level         = child.tqg_level;
            var pin               = child.pin;
            var total_active_user = child.total_active_user;
            var has_children      = child.has_children;
            var pin_id            = child.pin_id;
            var tqg_level_id      = child.tqg_level_id;
            var is_registered_guest = child.is_registered_guest;

            if (has_children) {
              var show_caret = "zmdi zmdi-caret-right";
              var wrapper_child_c = `<ul class="list-unstyled wrap-parent ml-3 hide" id="wrapper-child-${child_id}">` +
                                      `<li>` +
                                        `<div class="d-flex organization-list"></div>` +
                                      `</li>` +
                                    `</ul>`;
            } else {
              var show_caret = "";
              var wrapper_child_c = "";
            }

            if (deactivated != null) {
              var deactivated_text = `<span style="${deactivated_style}">` + I18n.t('global.deactivated_at') + ` : ${deactivated}</span>`;
            } else {
              var deactivated_text = ``;
            }

            account_detail = `${recognition_name}`;

            if (is_registered_guest) {
              var guest_label = I18n.t('global.registered_as_a_guest')
              user_detail_url = $("#pills-tabContent").attr("data-detail-guest-url") || "";
            } else {
              var guest_label = ''
            }

            if (user_detail_url.length > 0 && user_id != null) {
              account_detail = `<a target='_blank' href='${user_detail_url}/${user_id}'>${account_detail}</a>`;
            }

            // Detail Amway Pin & TQG Level
            amway_pin_detail = `${pin}`;
            if (amway_pin_detail_url.length > 0 && pin_id != null) {
              amway_pin_detail = `<a target='_blank' href='${amway_pin_detail_url}/${pin_id}'>${amway_pin_detail}</a>`;
            }

            tqg_level_detail = `${tqg_level}`;
            if (tqg_level_detail_url.length > 0 && tqg_level_id != null) {
              tqg_level_detail = `<a target='_blank' href='${tqg_level_detail_url}/${tqg_level_id}'>${tqg_level_detail}</a>`;
            }

            if ((event_id != null) || (variant_id != null)) {
              var own_ticket = child.own_ticket
              var total_ticket = child.total_ticket
              var children_table = `<ul class="list-unstyled wrap-parent ml-3 accordionContent" data-child="${level}">` +
                                      `<li class="li-${child_id}">` +
                                        `<div class="text-decoration-none organization-list-wrap align-items-center">` +
                                          `<div class="d-flex justify-content-between organization-list">` +
                                            `<div class="d-flex w-100">` +
                                              `<div class="organization-icon accordion-item" data-parent="${level+1}" data-account-id="${child_id}" id="account-${child_id}" data-event-id="${event_id}"  data-variant-id="${variant_id}">` +
                                                `<i class="${show_caret}"></i>` +
                                              `</div>` +
                                              `<div class="ml-3 w-100">` +
                                                `<span class="d-block">` +
                                                  account_detail +
                                                  `<b>(${abo_number})</b>` +
                                                `</span>` +
                                                `<span class="d-block">` +
                                                  `<small style="color: blue;">${guest_label}</small>` +
                                                `</span>` +
                                                `<div class="row">` +
                                                  `<div class="col-md-3">` +
                                                    `<span>` + I18n.t('accounts.pin') + ` : ${pin} </span>` +
                                                  `</div>` +
                                                  `<div class="col-md-3">` +
                                                    `<span>` + I18n.t('ticket_voucher.own_tickets') + ` : ${own_ticket}</span>` +
                                                  `</div>` +
                                                  `<div class="col-md-3">` +
                                                    `<span>` + I18n.t('ticket_voucher.total_tickets') + ` : ${total_ticket}</span>` +
                                                  `</div>` +
                                                `</div>` +
                                                `<div class="row">` +
                                                  `<div class="col-md-3">` +
                                                    `${deactivated_text}` +
                                                  `</div>` +
                                                `</div>` +
                                              `</div>` +
                                            `</div>` +
                                          `</div>` +
                                        `</div>` +
                                        wrapper_child_c +
                                      `</li>` +
                                   `</ul>`;
            } else {
              var children_table = `<ul class="list-unstyled wrap-parent ml-3 accordionContent" data-child="${level}">` +
                                      `<li class="li-${child_id}">` +
                                        `<div class="text-decoration-none organization-list-wrap align-items-center">` +
                                          `<div class="d-flex justify-content-between organization-list">` +
                                            `<div class="d-flex w-100">` +
                                              `<div class="organization-icon accordion-item" data-parent="${level+1}" data-account-id="${child_id}" id="account-${child_id}">` +
                                                `<i class="${show_caret}"></i>` +
                                              `</div>` +
                                              `<div class="ml-3 w-100">` +
                                                `<span class="d-block">` +
                                                  account_detail +
                                                  `<b>(${abo_number})</b>` +
                                                `</span>` +
                                                `<span class="d-block">` +
                                                  `<small style="color: blue;">${guest_label}</small>` +
                                                `</span>` +
                                                `<div class="row">` +
                                                  `<div class="col-md-3">` +
                                                    `<span style="${expired_style}">` + I18n.t('subscriptions.expiry') + ` : ${expiry}</span>` +
                                                  `</div>` +
                                                  `<div class="col-md-3">` +
                                                    `<span>` + I18n.t('global.tqg_level') + ` : ${tqg_level_detail}</span>` +
                                                  `</div>` +
                                                  `<div class="col-md-3">` +
                                                    `<span>` + I18n.t('accounts.pin') + ` : ${amway_pin_detail}</span>` +
                                                  `</div>` +
                                                  `<div class="col-md-3">` +
                                                    `<span>` + I18n.t('global.total_active_users') + ` : ${total_active_user}</span>` +
                                                  `</div>` +
                                                  `<div class="row">` +
                                                    `<div class="col-md-3">` +
                                                      `${deactivated_text}` +
                                                    `</div>` +
                                                  `</div>` +
                                                `</div>` +
                                              `</div>` +
                                            `</div>` +
                                          `</div>` +
                                          wrapper_child_c +
                                      `</li>` +
                                   `</ul>`;
            }
            data = data + children_table;
          });
          // wrapper_child.removeClass('blink');
          wrapper_child.html(data);
          $this.addClass('active-icon');
          $this.attr('data-haschild', true);
          $this.find('.zmdi').first().removeClass('zmdi-caret-right').addClass('zmdi-caret-down')
        }
      })
    }

    if($target.is(':hidden')){
      $this.removeClass('active-icon');
      $this.addClass('active-icon');
      $target.removeClass('active').slideUp();
      $target.addClass('active').slideDown();
      $this.find('.zmdi').first().removeClass('zmdi-caret-right').addClass('zmdi-caret-down')
    } else {
      $this.find('.zmdi').first().removeClass('zmdi-caret-down').addClass('zmdi-caret-right')
      $this.removeClass('active-icon');
      $target.removeClass('active').slideUp();
    }

    return false;
  });

  $('body').on('click', '.accordionUpline', function(){
    var $this         = $(this);
    var level         = parseInt($this.attr('data-parent'));
    var account_id    = $this.attr('data-account-id');
    var parentUpliner = $this.attr('data-parentUpliner');
    var wrapper_parent = $(`#wrapper-parent-${account_id}`);
    var parent_li     = $this.closest(`.wrap-parent li.li-${account_id}`);
    var $target       = parent_li.find('.accordionUplineContent[data-parent="'+level+'"]');
    // var $target       = $this.find('.accordionUplineContent[data-parent="'+level+'"]');
    var user_detail_url = $("#pills-tabContent").attr("data-detail-user-url") || "";
    var amway_pin_detail_url = $("#pills-tabContent").attr('data-detail-pin-url') || "";
    var tqg_level_detail_url = $("#pills-tabContent").attr('data-detail-tqg-level-url') || "";

    if (wrapper_parent.length > 0) {
      wrapper_parent.removeClass('hide');
    }

    if (!parentUpliner) {
      var main_path = window.location.pathname.includes("/user/") ? "user" : "admin";

      $.ajax({
        type: "GET",
        dataType:"json",
        data: { "data_parent" : level, "account_id": account_id },
        url: `/${main_path}/organization_charts/${account_id}/get_upline`,
        success: function(response){
          var data = "";
          $.each( response.upline, function( k, parent ) {
            var parent_id         = parent.id;
            var user_id           = parent.user_id;
            var recognition_name  = parent.recognition_name;
            var abo_number        = parent.abo_number;
            var expired           = parent.expired;
            var expiry            = parent.expiry;
            var deactivated_at    = parent.deactivated_at;
            var deactivated_style = parent.deactivated_style;
            var tqg_level         = parent.tqg_level;
            var pin               = parent.pin;
            var pin_id            = parent.pin_id;
            var tqg_level_id      = parent.tqg_level_id;
            var parent            = parent.total_active_user;

            if (parseInt(parent) >= 1) {
              var show_caret = "zmdi zmdi-caret-right";
              var wrapper_parent_p = `<ul class="list-unstyled ml-3 hide" id="wrapper-parent-${parent_id}">` +
                                      `<li class="accordion-item">` +
                                        `<div class="d-flex organization-list"></div>` +
                                      `</li>` +
                                    `</ul>`;
            } else {
              var show_caret = "";
              var wrapper_parent_p = "";
            }

            if (deactivated_at != null) {
              var deactivated_text = `<span style="${deactivated_style}">` + I18n.t('global.deactivated_at') + ` : ${deactivated_at}</span>`;
            } else {
              var deactivated_text = ``;
            }

            account_detail = `${recognition_name}`;
            if (user_detail_url.length > 0 && user_id != null) {
              account_detail = `<a target='_blank' href='${user_detail_url}/${user_id}'>${account_detail}</a>`;
            }

            // Detail Amway Pin & TQG Level
            amway_pin_detail = `${pin}`;
            if (amway_pin_detail_url.length > 0 && pin_id != null) {
              amway_pin_detail = `<a target='_blank' href='${amway_pin_detail_url}/${pin_id}'>${amway_pin_detail}</a>`;
            }

            tqg_level_detail = `${tqg_level}`;
            if (tqg_level_detail_url.length > 0 && tqg_level_id != null) {
              tqg_level_detail = `<a target='_blank' href='${tqg_level_detail_url}/${tqg_level_id}'>${tqg_level_detail}</a>`;
            }

            var parent_table = `<ul class="list-unstyled wrap-parent ml-3 accordionUplineContent" data-parent="${level}">` +
                                  `<li class="li-${parent_id}">` +
                                    `<div href="#" class="text-decoration-none organization-list-wrap align-items-center">` +
                                      `<div class="d-flex justify-content-between organization-list">` +
                                        `<div class="d-flex w-100">` +
                                          `<div class="organization-icon accordionUpline" data-parent="${level+1}" data-account-id="${parent_id}" id="account-${parent_id}">` +
                                            `<i class="${show_caret}"></i>` +
                                          `</div>` +
                                          `<div class="ml-3 w-100">` +
                                            `<span class="d-block">` +
                                              account_detail +
                                              `<b>(${abo_number})</b>` +
                                            `</span>` +
                                            `<div class="row">` +
                                              `<div class="col-md-4">` +
                                                `<span style="${expired}">` + I18n.t('subscriptions.expiry') + ` : ${expiry}</span>` +
                                              `</div>` +
                                              `<div class="col-md-4">` +
                                                `<span>` + I18n.t('global.tqg_level') + ` : ${tqg_level_detail}</span>` +
                                              `</div>` +
                                              `<div class="col-md-4">` +
                                                `<span>` + I18n.t('accounts.pin') + ` : ${amway_pin_detail}</span>` +
                                              `</div>` +
                                            `</div>` +
                                            `<div class="row">` +
                                              `<div class="col-md-3">` +
                                                `${deactivated_text}` +
                                              `</div>` +
                                            `</div>` +
                                          `</div>` +
                                        `</div>` +
                                      `</div>` +
                                    `</div>` +
                                    wrapper_parent_p +
                                  `</li>` +
                                `</ul>`;

            data = data + parent_table;
          });

          wrapper_parent.html(data);
          $this.addClass('active-icon');
          $this.attr('data-parentUpliner', true);
          $this.find('.zmdi').first().removeClass('zmdi-caret-right').addClass('zmdi-caret-down')
        }
      })
    }

    if($target.is(':hidden')){
      $this.removeClass('active-icon');
      $this.addClass('active-icon');
      $target.removeClass('active').slideUp();
      $target.addClass('active').slideDown();
      $this.find('.zmdi').first().removeClass('zmdi-caret-right').addClass('zmdi-caret-down')
    } else {
      $this.find('.zmdi').first().removeClass('zmdi-caret-down').addClass('zmdi-caret-right')
      $this.removeClass('active-icon');
      $target.removeClass('active').slideUp();
    }

    return false;
  });
});