$(document).ready(function() {
  setUpGetPrevTier();

  $('#get_achievement_goal').change(function() {
    setUpGetPrevTier();
  })

  $('#existing_achievement_id').change(function() {
    updateAchievementGoalOptions($(this).val());
  })

  $('.btn-save-qualification').click(function() {
    if (!$('#get_achievement_goal')[0].disabled) {
      var range_tiers = $('#range_tiers').data('range-tier');

      if (!!$('#get_achievement_goal').val()) {
        if (confirm(range_tiers) == false) {
          return false;
        }
      }
    }
  })

  $('.btn-save-incentive-trip').click(function() {
    if ($('#priview_banner').length >= 1) {
      return true;
    }

    if (!$('#incentive_trip_banner').val()) {
      alert("Banner can't blank!")
      return false;
    }
  })

  $('#select-year-incentive-trip').change(function() {
    var year = $(this).val();
    filterSummary(year);
  })

  $("#refresh-incentive-data").click(function() {
    var year = $("#select-year-incentive-trip").val();
    var text_alert = I18n.t('global.notices.do_you_want_to_refresh_the_data') + ` ${year} ?`

    if (confirm(text_alert)) {
      $.ajax({
        type: "GET",
        dataType:"script",
        data: { "year" : year },
        url: "/user/incentive_trips/refresh"
      })
      return true
    } else {
      return false
    }
  });

  function updateAchievementGoalOptions(existing_achievement_id) {
    var incentive_trip_id = $('#incentive_qualification_incentive_trip_id').val();

    $.ajax({
      type: "GET",
      dataType:"json",
      data: { 'existing_achievement_id' : existing_achievement_id },
      url: `/admin/incentive_trips/${incentive_trip_id}/incentive_qualifications/get_achievement_goal_options`,
      success: function(response){
        $('#get_achievement_goal').val('');
        $('#get_achievement_goal').empty();
        $('#achievement-tier-detail').empty();

        achievement_goals = response.achievement_goals;
        $('#get_achievement_goal').append("<option value>" + I18n.t('actions.select', { item: I18n.t('global.achievement_goal') }) + "</option>");

        $.each(achievement_goals, function(_key, data) {
          let option = $("<option></option>");
              option.attr("value", data.id);
              option.text(`${data.level_name} and above`);

          $('#get_achievement_goal').append(option);
        })
      }
    });
  }


  function setUpGetPrevTier() {
    var achievement_goal_id  = $('#get_achievement_goal').val()
    var incentive_trip_id    = $('#incentive_qualification_incentive_trip_id').val();
    var existing_achievement = $('#existing_achievement_id option:selected').text();

    if (!!achievement_goal_id) {
      getPrevTier(achievement_goal_id, incentive_trip_id, existing_achievement);
    }
  }

  function getPrevTier(achievement_goal_id, incentive_trip_id, existing_achievement) {
    $.ajax({
      type: "GET",
      dataType:"json",
      data: { 'achievement_goal_id' : achievement_goal_id },
      url: `/admin/incentive_trips/${incentive_trip_id}/incentive_qualifications/get_prev_achievement_goal`,
      success: function(response){
        if (existing_achievement == response.prev_goal_tier) {
          var alert_text = I18n.t('global.notices.users_with_level') + ` ${existing_achievement} ` + I18n.t('global.notices.must_reach') + ` ${response.goal_tier} ` + I18n.t('global.notices.or_above');
        } else {
          var alert_text = I18n.t('global.notices.users_with_level') + ` ${existing_achievement} - ${response.prev_goal_tier} ` + I18n.t('global.notices.must_reach') + ` ${response.goal_tier} ` + I18n.t('global.notices.or_above');
        }

        $('#range_tiers').data('range-tier', I18n.t('global.notices.are_you_sure') + ` \n ${alert_text}`);
        $('#achievement-tier-detail').html(`#${alert_text}`);
      }
    });
  }

  function filterSummary(year){
    $.ajax({
      type: "GET",
      dataType:"script",
      data: { 'year' : year },
      url: "/user/incentive_trips"
    });
  }

  function headerAchievement(achievement_goal_name) {
    var header_achievement = `<div class="business-status-item font-weight-bold mb-3">`+
                              `<span class="text-dark">${achievement_goal_name}</span>`+
                             `</div>`;
    $('.header-achievements').html(header_achievement);
  }

  function showAchievementSummary(achievements, goal_present) {
    new_achievements = achievements.new_achievements;
    $('.new_achievements').remove();

    if ((new_achievements.length <= 0) && !goal_present) {
      var condition_strip = `<div class='new_achievements'>`+
                              `<span class="text-dark">-</span>`+
                            `<div>`;
      $('.body-achievements').append(condition_strip);
      return false;
    }

    $.each(new_achievements, function( index, value ) {
      var body_achievements = `<div class='new_achievements'>`+
                                `<span class="text-dark">${value[0]} ${value[1]}</span>`+
                                `&nbsp;<i class="zmdi zmdi-check-circle"></i>`+
                                `<br>`+
                              `<div>`;
      $('.body-achievements').append(body_achievements);
    });
  }
});
