$(document).ready(function() {
  $('#new-lucky_draw-item').click(function() {
    var new_lucky_draw_item = 'new'
    var lucky_draw_round    = $('#lucky_draw_round_id').val()
    var lucky_draw_item     = ''
    var text_submit         = I18n.t('actions.new_lucky_draw_item')

    flashMessage()
    addOptionInventory(new_lucky_draw_item, lucky_draw_round, lucky_draw_item, text_submit)
  })

  $('.edit-lucky-draw-item').click(function() {
    var new_lucky_draw_item = 'edit'
    var lucky_draw_round    = $('#lucky_draw_round_id').val()
    var lucky_draw_item     = $(this).data('id-lucky-draw-item')
    var text_submit         = I18n.t('actions.edit_lucky_draw_item')

    flashMessage()
    addOptionInventory(new_lucky_draw_item, lucky_draw_round, lucky_draw_item, text_submit)
  })

  function flashMessage() {
    var notice = `<div class="alert alert-success">`+
                      `<span class="close" data-dismiss="alert">×</span>`+
                      I18n.t('global.processing')+
                     `</div>`;

    $('.flash-messages').html(notice)
  }

  function addOptionInventory(new_lucky_draw_item, lucky_draw_round, lucky_draw_item, text_submit) {
    $.ajax({
      type: "GET",
      dataType:"json",
      data: { 'lucky_draw_item' : new_lucky_draw_item, 'id' : lucky_draw_round, 'lucky_draw_item_id' : lucky_draw_item },
      url: "/admin/lucky_draw_rounds/get_inventory",
      success: function(response){
        var events = response.events
        var url    = response.url
        var selected_events = response.selected_events

        $('#submit_form').val(text_submit)
        $('#form-new-lucky-draw-item').attr('action', url)
        $("#event_ids option").remove();

        $.each(events, function(_key, data) {
          let option = $("<option></option>");
              option.attr("value", data[0]);
              option.text(`${data[1]} - ${data[2]}`);

          $('#event_ids').append(option);
        })

        if (selected_events != '') {
          $(`#event_ids`).val(selected_events)
        }

        $('#exampleModal').modal('show')
        $('.alert.alert-success').remove()
      }
    });
  }

  $('body').on('click', '.remove-inventory', function() {
    var inventory = $(this).parents('.nested-inventory')
        inventory.find('select').val('')
        inventory.addClass('d-none')
        $(this).addClass('d-none')
  });
});