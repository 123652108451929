import 'uppy/dist/uppy.min.css'

import {
  Core,
  FileInput,
  Informer,
  ProgressBar,
  ThumbnailGenerator,
  XHRUpload,
} from 'uppy'

function fileMultiUploadLocal(fileInput) {
  const is_video        = fileInput.dataset.isVideo,
        checked_isvideo = is_video == 'true',
        allowed_file    = fileInput.dataset.availableMimetype,
        imagePreview    = getImagePreviewClass(fileInput),
        form            = $(fileInput).parents('form'),
        submitButton    = document.getElementById('button-form-uppy'),
        formGroup       = fileInput.parentNode,
        hiddenInput     = getHiddenInput(fileInput, checked_isvideo),
        limit_upload    = fileInput.dataset.limitUpload == 'true',
        csrfToken       = document.querySelector('meta[name="csrf-token"]').content,
        max_file_size   = fileInput.dataset.maxFileSize,
        is_single_video = fileInput.dataset.singleVideo == 'true';

  // remove our file input in favour of Uppy's
  formGroup.removeChild(fileInput)

  const uppy = Core({
      autoProceed: true,
      restrictions: {
        maxTotalFileSize: max_file_size == undefined ? null : parseInt(max_file_size) * 1024 * 1024,
        allowedFileTypes: allowed_file == undefined ? null : JSON.parse(fileInput.dataset.availableMimetype)
      },
      onBeforeFileAdded: (currentFile, files) => {
        currentFile['name'] = (new Date().getTime()) + "-" +  currentFile['name'];
      }
    })
    .use(FileInput, {
      target: formGroup,
    })
    .use(Informer, {
      target: formGroup,
    })
    .use(ProgressBar, {
      target: imagePreview.parentNode,
    })
    .use(ThumbnailGenerator, {
      thumbnailWidth: 600,
    })
    .use(XHRUpload, {
      endpoint: '/images/upload', bundle: false, headers: { "X-CSRF-Token": csrfToken }
    })

  uppy.on('thumbnail:generated', (file, preview) => {
    // show preview of the image using URL from thumbnail generator
    var div = document.createElement("div");
        div.className = 'col-3 image-col mt-auto';
        $(div).attr('data-filename', file['name']);

    var img = document.createElement("img");
    $(div).append(img)

    var span  = document.createElement("span");
    var ahref = document.createElement("a");
        ahref.className = 'remove-cache-image';
        ahref.innerHTML = 'Remove';
        ahref.href = 'javascript:void(0)';

    var file_input_id = $(fileInput).attr('id');
    if (file_input_id == 'input-weigh-out-photo' ) {
      $(ahref).addClass('remove-cache-weigh-out');
    }

    $(span).append(ahref);
    $(div).append(span);

    img.src = preview;
    img.className = 'w-100';
    $(imagePreview).append(div);
  })

  uppy.on('upload-success', (file, response) => {
    if (file.type.includes('video')) {
      var ul = getVideoListClass(fileInput);
      var ahref = document.createElement('a');
          ahref.innerHTML = file.name;
          ahref.href = `http://localhost:3000/uploads/cache/${response.body.id}`;
          ahref.target = '_blank';
      var ahref2 = document.createElement("a");
          ahref2.className = 'remove-cache-image';
          ahref2.innerHTML = 'Remove';
          ahref2.href = 'javascript:void(0)';
      var li = document.createElement('li');
          li.setAttribute('data-cache-id', response.body.id);

      if (checked_isvideo) {
        ahref.dataset.isVideo = 'true';
        ahref2.dataset.isVideo = 'true';
      }

      var file_input_id = $(fileInput).attr('id');
      if (file_input_id == 'input-weigh-out-photo' ) {
        $(ahref2).addClass('remove-cache-weigh-out');
      }

      $(li).append($(ahref));

      if (!is_single_video) {
        $(li).append(' - ');
        $(li).append($(ahref2));
      }

      limit_upload ? ul.html(li) : ul.append(li);

      $('.video-blank').remove();
    }

    if (file.type.split('/')[0] == 'image') {
      var div = $("div[data-filename='"+ file['name'] +"']")
          div.attr('data-cache-id', response.body.id)
    }

    // construct uploaded file data in the format that Shrine expects
    const uploadedFileData = {
      id: response.body.id, // object key without prefix
      storage: 'cache',
      metadata: {
        size: response.body.metadata['size'],
        filename: response.body.metadata['filename'],
        mime_type: response.body.metadata['mime_type'],
      }
    }

    // set hidden field value to the uploaded file data so that it's submitted
    // with the form as the attachment

    if (limit_upload) {
      hiddenInput.value = "";
    }
    var old_value = []
    if (hiddenInput.value != "") {
      old_value = JSON.parse(hiddenInput.value)
    }

    old_value.push(uploadedFileData)
    hiddenInput.value = JSON.stringify(old_value)
    setTimeout(() => {
    }, 1500)
  })

  uppy.on('upload-error', (file, error, response) => {
    submitButton.removeAttribute("disabled")
    submitButton.innerHTML = I18n.t('actions.save');
  })
}

function getHiddenInput(fileInput, checked_isvideo) {
  var file_input_id = $(fileInput).attr('id');
  if (checked_isvideo) {
    if (file_input_id == 'input-weigh-out-video') {
      return document.querySelector('#upload-data-weigh-out-video');
    } else {
      return document.querySelector('.upload-data-video');
    }
  } else {
    if (file_input_id == 'input-weigh-out-photo') {
      return document.querySelector('#upload-weigh-out-data');
    } else {
      return document.querySelector('.upload-data');
    }
  }
}

function getVideoListClass(fileInput) {
  var file_input_id = $(fileInput).attr('id');
  if (file_input_id == 'input-weigh-out-video') {
    return $('.list-of-weigh-out-video');
  } else {
    return $('.list-of-video');
  }
}

function getImagePreviewClass(fileInput) {
  var file_input_id = $(fileInput).attr('id');
  if (file_input_id == 'input-weigh-out-photo') {
    return document.querySelector('.upload-weigh-out-preview');
  } else {
    return document.querySelector('.upload-preview');
  }
}

export default fileMultiUploadLocal
