$(document).ready(function(){
  var startSeqs = {};
  var startNum = 0;

  // jQuery FN
  $.fn.playSpin = function (options) {
    $('.draw-number-0').toggleClass('draw-number-spin');
    if (this.length) {
      if ($(this).is(':animated')) return; // Return false if this element is animating
      startSeqs['mainSeq' + (++startNum)] = {};
      $(this).attr('data-playslot', startNum);

      var total = this.length;
      var thisSeq = 0;

      // Initialize options
      if (typeof options == 'undefined') {
        options = new Object();
      }

      // Pre-define end nums
      var endNums = [];
      // console.log(options.endNum)
      if (typeof options.endNum != 'undefined') {
        if ($.isArray(options.endNum)) {
          endNums = options.endNum;
        } else {
          endNums = [options.endNum];
        }
      }

      for (var i = 0; i < this.length; i++) {
        if (typeof endNums[i] == 'undefined') {
          endNums.push(0);
        }
      }

      startSeqs['mainSeq' + startNum]['totalSpinning'] = total;
      return this.each(function () {
        options.endNum = endNums[thisSeq];
        startSeqs['mainSeq' + startNum]['subSeq' + (++thisSeq)] = {};
        startSeqs['mainSeq' + startNum]['subSeq' + thisSeq]['spinning'] = true;
        var track = {
          total: total,
          mainSeq: startNum,
          subSeq: thisSeq
        };
        (new slotMachine(this, options, track));
      });
    }
  };

  $.fn.stopSpin = function () {
    if (this.length) {
      if (!$(this).is(':animated')) return; // Return false if this element is not animating
      if ($(this)[0].hasAttribute('data-playslot')) {
        $.each(startSeqs['mainSeq' + $(this).attr('data-playslot')], function(index, obj) {
          obj['spinning'] = false;
        });
      }
    }
  };

  var slotMachine = function (el, options, track) {
    var slot = this;
    slot.$el = $(el);

    slot.defaultOptions = {
      easing: 'swing',        // String: easing type for final spin
      time: 3000,             // Number: total time of spin animation
      loops: 6,               // Number: times it will spin during the animation
      manualStop: false,      // Boolean: spin until user manually click to stop
      stopSeq: 'random',      // String: sequence of slot machine end animation, random, leftToRight, rightToLeft
      endNum: 0,              // Number: animation end at which number/ sequence of list
      onEnd : $.noop,         // Function: run on each element spin end, it is passed endNum
      onFinish: $.noop        // Function: run on all element spin end, it is passed endNum
    };

    slot.spinSpeed = 0;
    slot.loopCount = 0;

    slot.init = function () {
      slot.options = $.extend({}, slot.defaultOptions, options);
      slot.setup();
      slot.startSpin();
    };

    slot.setup = function () {
      var $li = slot.$el.find('li').first();
      slot.liHeight = $li.innerHeight();
      slot.liCount = slot.$el.children().length;
      slot.listHeight = slot.liHeight * slot.liCount;
      slot.spinSpeed = slot.options.time / slot.options.loops;

      $li.clone().appendTo(slot.$el); // Clone to last row for smooth animation

      // Configure stopSeq
      if (slot.options.stopSeq == 'leftToRight') {
        if (track.subSeq != 1) {
          slot.options.manualStop = true;
        }
      } else if (slot.options.stopSeq == 'rightToLeft') {
        if (track.total != track.subSeq) {
          slot.options.manualStop = true;
        }
      }
    };

    slot.startSpin = function () {
      slot.$el
        .css('top', -slot.listHeight)
        .animate({'top': '0px'}, slot.spinSpeed, 'linear', function () {
          slot.lowerSpeed();
      });
    };

    slot.lowerSpeed = function () {
      slot.loopCount++;

      if (slot.loopCount < slot.options.loops ||
        (slot.options.manualStop && startSeqs['mainSeq' + track.mainSeq]['subSeq' + track.subSeq]['spinning'])) {
        slot.startSpin();
      } else {
        slot.endSpin();
      }
    };

    slot.endSpin = function () {
      // Error handling if endNum is out of range
      if (slot.options.endNum < 0 || slot.options.endNum > slot.liCount) {
        slot.options.endNum = 1;
      }

      var finalPos = -((slot.liHeight * slot.options.endNum));
      var finalSpeed = ((slot.spinSpeed * 1.5) * (slot.liCount)) / slot.options.endNum;

      slot.$el
      .css('top', -slot.listHeight)
      .animate({'top': finalPos}, finalSpeed, slot.options.easing, function () {
        slot.$el.find('li').last().remove(); // Remove the cloned row

        slot.endAnimation(slot.options.endNum);
        if ($.isFunction(slot.options.onEnd)) {
          slot.options.onEnd(slot.options.endNum);
        }

        // onFinish is every element is finished animation
        if (startSeqs['mainSeq' + track.mainSeq]['totalSpinning'] == 0) {
          var totalNum = '';
          $.each(startSeqs['mainSeq' + track.mainSeq], function(index, subSeqs) {
            if (typeof subSeqs == 'object') {
              totalNum += subSeqs['endNum'].toString();
            }
          });
          if ($.isFunction(slot.options.onFinish)) {
            slot.options.onFinish(totalNum);
          }
        }
      });
      // PlayAnimation();
    }

    slot.endAnimation = function(endNum) {
      if (slot.options.stopSeq == 'leftToRight' && track.total != track.subSeq) {
        startSeqs['mainSeq' + track.mainSeq]['subSeq' + (track.subSeq + 1)]['spinning'] = false;
      } else if (slot.options.stopSeq == 'rightToLeft' && track.subSeq != 1) {
        startSeqs['mainSeq' + track.mainSeq]['subSeq' + (track.subSeq - 1)]['spinning'] = false;
      }
      startSeqs['mainSeq' + track.mainSeq]['totalSpinning']--;
      startSeqs['mainSeq' + track.mainSeq]['subSeq' + track.subSeq]['endNum'] = endNum;
    }
    this.init();
  };

  function PlayAnimation(){
    $('.draw-number-0').removeClass('draw-number-spin');
    $('.dr-number').addClass('winner-dr-number');
    $('.draw-number').addClass('winner-draw-number');
    $('.draw-number-show').addClass('winner-draw-show');
    $('#winner-recognition-name').addClass('winner-recognition-name');
    $('#winner-email').addClass('winner-email');
    // $('.dr-number').addClass('jello-lucky-draw');
    $('#btn-spin').prop('disabled', false);
    $('.winner-recognition-name').removeClass("d-none");
    $('.winner-email').removeClass("d-none");

    // stop spinning audio
    var audio = new Audio();
    audio.src = "/assets/ding.wav";
    audio.play();
  }

  // START LUCKY DRAW
  $('#btn-spin.spin-lucky-draw').click(function() {
    if ($('#winner-recognition-name').text().length != 0){
      var draw_count = parseInt($('#draw_number').text().split("Draw ")[1])+1;
      $('#draw_number').text("Draw "+draw_count);
    }

    $(this).prop('disabled', true);
    $('.draw-number').removeClass('winner-draw-number');
    $('.dr-number').removeClass('winner-dr-number');
    $('.draw-number-show').removeClass('winner-draw-show');
    $('#winner-recognition-name').removeClass('winner-recognition-name');
    $('.winner-recognition-name').addClass("d-none");
    $('#winner-recognition-name').text("");

    $('#winner-email').removeClass('winner-email');
    $('.winner-email').addClass("d-none");
    $('#winner-email').text("");

    var audio = new Audio();
    audio.src = "/assets/spinning.mp3";
    audio.play();

    audio.addEventListener('ended', function() {
      this.currentTime = 0;
      this.play();
    }, false);

    var lucky_draw_item = $('#lucky-draw-item').val();

    var end_count = 0;

    // FOR TESTING
    // $('#winner-recognition-name').text("WINNER NAME");
    // $('#lucky-draw ul').playSpin({
    //   endNum: [1,2,3,4,5,6,7,9,9,9],
    //   onEnd: function(){
    //     end_count++;
    //     if(end_count === 10) {
    //       PlayAnimation();
    //       audio.pause();
    //     }
    //   }
    // });

    $.ajax({
      type: 'GET',
      url: `/lucky_draws/spin_winner/lucky_draw_item_id/${lucky_draw_item}`,
      dataType: 'JSON',
      success: function (result) {
        var winner_abo_number = result.split_abo_number;
        var error_message = result.message;

        if (error_message == undefined) {
          $('#lucky-draw ul').playSpin({
            endNum: winner_abo_number,
            onEnd: function(){
              end_count++;
              if(end_count === 10) {
                PlayAnimation();
                $('#winner-recognition-name').text(result.recognition_name);
                audio.pause();
              }
            }
          });
        } else {
          audio.pause();
          alert(error_message)
        }
      },
      error: function (xhr, ajaxOptions, thrownError) {
        alert(I18n.t('global.alerts.something_went_wrong'))
      }
    });
  });
  // END LUCKY DRAW

  // START BODYFIT+ LUCKY DRAW
  $('#btn-spin.spin-ad-hoc-ld').click(function() {
    if ($('#winner-recognition-name').text().length != 0){
      var draw_count = parseInt($('#draw_number').text().split("Draw ")[1])+1;
      $('#draw_number').text("Draw "+draw_count);
    }

    $(this).prop('disabled', true);
    $('.draw-number').removeClass('winner-draw-number');
    $('.dr-number').removeClass('winner-dr-number');
    $('.draw-number-show').removeClass('winner-draw-show');
    $('#winner-recognition-name').removeClass('winner-recognition-name');
    $('.winner-recognition-name').addClass("d-none");
    $('#winner-recognition-name').text("");

    $('#winner-email').removeClass('winner-email');
    $('.winner-email').addClass("d-none");
    $('#winner-email').text("");

    var current_prize = $("#current-prize").val();

    if (current_prize.length > 0) {
      $('#prize-name-detail').text(`Prize : ${current_prize}`);
    } else {
      $('#prize-name-detail').text('');
    }

    var audio = new Audio();
      audio.src = "/assets/spinning.mp3";
    audio.play();

    audio.addEventListener('ended', function() {
      this.currentTime = 0;
      this.play();
    }, false);

    var ad_hoc_ld_id = $('#ad-hoc-lucky-draw-id').val();
    var draw_number  = $('#draw-number').val();

    var end_count = 0;

    // FOR TESTING
    // $('#winner-recognition-name').text("WINNER NAME");
    // $('#lucky-draw ul').playSpin({
    //   endNum: [1,2,3,4,5,6,7,9,9,9],
    //   onEnd: function(){
    //     end_count++;
    //     if(end_count === 10) {
    //       PlayAnimation();
    //       audio.pause();
    //     }
    //   }
    // });

    $.ajax({
      type: 'GET',
      // url: `/lucky_draws/spin_winner/lucky_draw_item_id/${lucky_draw_item}`,
      url: `/ad_hoc_lucky_draws/${ad_hoc_ld_id}/spin_winner`,
      data: { 'draw_number' : draw_number },
      dataType: 'JSON',
      success: function (result) {
        var winner_abo_number = result.split_abo_number;
        var error_message = result.message;

        if (error_message == undefined) {
          var next_prize = result.next_prize;

          $('#lucky-draw ul').playSpin({
            endNum: winner_abo_number,
            onEnd: function(){
              end_count++;
              if(end_count === 10) {
                PlayAnimation();
                $('#winner-recognition-name').text(result.recognition_name);
                $('#winner-email').text(result.email);
                $('#current-prize').val(next_prize);
                audio.pause();
              }
            }
          });
        } else {
          audio.pause();
          alert(error_message)
        }
      },
      error: function (xhr, ajaxOptions, thrownError) {
        alert(I18n.t('global.alerts.something_went_wrong'))
      }
    });
  });
  // END BODYFIT+ LUCKY DRAW
});
