$(document).ready(function() {
  setTimeout(() => {
    $('.multiple-select2').find('.select2-selection--multiple').addClass('select2-container-size');
    $('.multiple-select2').find('.select2-container--default').addClass('select2-size');
  }, 10);

  // turn on/off button upgrade ticket
  $("[name^='checked_ticket']").change(function() {
    if ($("[name^='checked_ticket']:checked").length < 1) {
      $("#upgrade-btn").prop("disabled", true);
    } else {
      $("#upgrade-btn").prop("disabled", false);
    }
  }).change();

  // Dashboard user filter balance
  setTimeout(() => {
    var balance_groups = $('#filter_balance_groups').is(':checked');

    if (balance_groups) {
      $('#filter_balance_groups').change();
    }
  }, 15);

  // Validation button submit edit profile
  $('#submit-form-profile').click(function() {
    var nested_fields = $('.nested-fields').length
    var image_upload  = $('#image-upload').val().length;
    var error_msg     = [];


    if (!$('#hidden-picture-id').val()) {
      var gallery_img = 0;
    } else {
      var gallery_img = $('#hidden-picture-id').val();
    }

    if (nested_fields <= 0) {
      error_msg.push(I18n.t('global.alerts.pin_level_cant_be_blank'));
    }

    if ((image_upload <= 0) && (gallery_img <= 0)) {
      error_msg.push(I18n.t('global.alerts.image_cant_be_blank'));
    }

    if (error_msg.length > 0) {
      alert(error_msg.toString());
      return false
    }
  })

  // User page validation number phone
  $('#personal_contact2').change(function() {
    if ($('#personal_contact2').val() != '' || $('#personal_contact2_code').val() != '') {
      $('#personal_contact2').prop('required', true)
      $('#personal_contact2_code').prop('required', true)
    } else {
      $('#personal_contact2').prop('required', false)
      $('#personal_contact2_code').prop('required', false)
    }
  }).change()

  $('#personal_contact2').change(function() {
    var text_length = $(this).val().length
    var get_id      = '#personal_contact2_code'

    validationPhoneNumber(text_length, get_id)
  })

  $('#personal_contact2').keyup(function() {
    var text_length = $(this).val().length
    var get_id      = '#personal_contact2_code'

    validationPhoneNumber(text_length, get_id)
  })

  // User page validation number phone
  $('#personal_contact2_code').change(function() {
    var text_length = $(this).val().length
    var get_id      = '#personal_contact2'

    validationPhoneNumber(text_length, get_id)
  })

  $('#personal_contact2_code').keyup(function() {
    var text_length = $(this).val().length
    var get_id      = '#personal_contact2'

    validationPhoneNumber(text_length, get_id)
  })

  function validationPhoneNumber(phone_number, get_id) {
    if (phone_number > 0 || phone_number != '') {
      $(`${get_id}`).attr('required', true)
    } else {
      $(`${get_id}`).removeAttr('required')
    }
  }

  $('#use_balance').change(function() {
    var dataGrandTotalBef   = parseFloat($('.order_use-balance').attr('data-grand-total-bef')).toFixed(2);
    var dataCurrentMutation = parseFloat($('.order_use-balance').attr('data-current-mutation')).toFixed(2);
    if ($(this).prop('checked')) {
      $('.grand_total_amount strong').text('RM'+ (dataGrandTotalBef - dataCurrentMutation))
      $('.order_use-balance').removeClass('d-none')
    } else {
      $('.grand_total_amount strong').text('RM'+ (dataGrandTotalBef))
      $('.order_use-balance').addClass('d-none')
    }
  })

  $(".checked-radio").on("click", function() {
    $(this).find('input[type=radio]').prop("checked", true);
  });

  $('#select_year_core_performer').change(function() {
    var year = $(this).val();

    $.ajax({
      type: "GET",
      dataType:"script",
      data: { 'period' : year },
      url: $(this).data('url')
    });
  })

  $(".copy-link-ticket").click(function() {
    $('.copy-section-ticket').attr('style', 'display: none;');
    var id_link = $(this).attr('data-id');
    var section = $(`.copy-link-section-ticket-${id_link}`);
    var input   = $(`#link-stream-${id_link}`);

    section.css('display', 'none')
    input.removeAttr('style')
    section.toggle(200);

    input.select();
    document.execCommand("copy");
    input.css('display', 'none')
    alert(I18n.t('global.link_copied'));
  });

  // toggle sidebar
  $('.switch_toggle_user').click(function() {
    if (!$('.sidebar-nav').hasClass('mini_sidebar')) {
      $('.sidebar-item').parents('li').removeClass(['sidebar-item', 'sidebar-item-user'])
    } else {
      $('.sidebar-item').parents('li').addClass(['sidebar-item', 'sidebar-item-user'])
    }

    if ($('.sidebar-link').hasClass('active')) {
      $('.first-level-item').removeClass('in')
    }
  })
})
