import 'uppy/dist/uppy.min.css'

import {
  Core,
  FileInput,
  Informer,
  ProgressBar,
  ThumbnailGenerator,
  XHRUpload,
} from 'uppy'

const csrfToken = document.querySelector('meta[name="csrf-token"]').content
const callSingleFileUploadLocal = (fileInput) => fileUploadLocal(fileInput);
window.callSingleFileUploadLocal = callSingleFileUploadLocal;

function fileUploadLocal(fileInput) {
  var is_nested_img = ($('#nested-upload-img').length > 0);

  // const hiddenInput = document.querySelector('.upload-data#' + fileInput.id),
  if (is_nested_img) {
    var hiddenInput = document.querySelector('.upload-data#nested-img-' + fileInput.dataset.idx);
  } else {
    var hiddenInput = document.querySelector('.upload-data');
  }

  const form = $(fileInput).parents('form'),
        submitButton = document.getElementById('button-form-uppy'),
        formGroup = fileInput.parentNode;

  if ($(fileInput).data('previewId')) {
    var imagePreview = document.querySelector('#' + $(fileInput).data('previewId'));
  } else{
    if ($('#nested-upload-img').length > 0) {
      var parent_elm   = $(fileInput).closest(".parent-nested-img");
      var imagePreview = parent_elm.children('.upload-preview').find('img')[0];
    } else {
      var imagePreview = document.querySelector('.upload-preview img');
    }
  }

  // remove our file input in favour of Uppy's
  formGroup.removeChild(fileInput)

  const uppy = Core({
      autoProceed: true,
      onBeforeFileAdded: (currentFile, files) => {
        currentFile['name'] = (new Date().getTime()) + "-" +  currentFile['name'];
      }
    })
    .use(FileInput, {
      target: formGroup,
    })
    .use(Informer, {
      target: formGroup,
    })
    .use(ProgressBar, {
      target: imagePreview.parentNode,
    })
    .use(ThumbnailGenerator, {
      thumbnailWidth: 600,
    })
    .use(XHRUpload, { endpoint: '/images/upload', bundle: false, headers: { "X-CSRF-Token": csrfToken } })

  uppy.on('thumbnail:generated', (file, preview) => {
    // show preview of the image using URL from thumbnail generator
    imagePreview.src = preview;
  })

  uppy.on('upload-success', (file, response) => {
    // set hidden field value to the uploaded file data so that it's submitted
    // with the form as the attachment
    // construct uploaded file data in the format that Shrine expects
    const uploadedFileData = {
      id: response.body.id, // object key without prefix
      storage: 'cache',
      metadata: {
        size: response.body.metadata['size'],
        filename: response.body.metadata['filename'],
        mime_type: response.body.metadata['mime_type'],
      }
    }
    
    hiddenInput.value = JSON.stringify(uploadedFileData);

    $('#submit-resource').attr('disabled', false);
    setTimeout(() => {
      // form.submit()
    }, 1500)
  })

  uppy.on('upload-error', (file, error, response) => {
    submitButton.removeAttribute("disabled")
    submitButton.innerHTML = I18n.t('actions.save')
  })
}

export default fileUploadLocal